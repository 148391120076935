import type { SgwtConnectHTMLElement } from '@sgwt/sgwt-widgets-react';
import { logFrontError } from 'utils/errors/logFrontError';
import { getFrontLogger } from 'utils/loggers/FrontLogger';
import { initSgBootstrap } from 'utils/theming';
import { redirectToRequestAccessPage } from 'utils/url';
import { sgwtConnect } from './api/connect';

setupSgwtConnectWidget();

function setupSgwtConnectWidget() {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      const handleSgwtConnectReady = async () => {
        try {
          widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
          widget.setSgwtConnectInstance(sgwtConnect);
        } catch (error) {
          logFrontError('Error while setting sgwt-connect: {@FrontError}', error);
        }
      };

      widget?.addEventListener('sgwt-connect--ready', handleSgwtConnectReady, { once: true });
    } else {
      try {
        widget.setSgwtConnectInstance(sgwtConnect);
      } catch (error) {
        logFrontError('Error while setting sgwt-connect: {@FrontError}', error);
      }
    }
  }
}

window.sgwtConnect = sgwtConnect;

if (sgwtConnect.isAuthorized()) {
  const variation = window.SGWTWidgetConfiguration.context.getSgDesignSystemTheme() ?? 'standard';
  initSgBootstrap(variation);
  const frontLogger = getFrontLogger();
  frontLogger.startLogSession();
  import('bootstrapper/main').then(({ onSgConnectAuthenticated }) => {
    onSgConnectAuthenticated();
  });
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError) {
    const frontLogger = getFrontLogger();
    frontLogger.error('Authorization failed with error {@FrontError}', sgwtConnect.getAuthorizationError());
    redirectToRequestAccessPage();
  } else {
    sgwtConnect.requestAuthorization();
  }
}
export { getRootUrl } from 'utils/browser/browser';
